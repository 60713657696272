(function () {
	'use strict';
	angular
		.module('app')
		.factory('calendarInit', [
			'$q',
			'$timeout',
			'$rootScope',
			'$location',
			'$translate',
			'calendarOptions',
			'urlParameters',
			'utilities',
			'dataStore',
			'manageSettings',
			'calendarIO',
			'calendarEvents',
			'daybackIO',
			'seedcodeCalendar',
			'manageSchedules',
			'manageEventSources',
			'manageResources',
			'manageGridTimes',
			'manageFilters',
			'manageMap',
			'hash',
			'crypt',
			'manageTheme',
			'environment',
			'csvData',
			'manageCalendarActions',
			'filemakerJS',
			'shares',
			'fieldFormatting',
			'groupSelector',
			calendarInit,
		])
		.factory('manageResources', [
			'dataStore',
			'seedcodeCalendar',
			'manageSettings',
			'manageConfig',
			'$timeout',
			manageResources,
		])
		.factory('manageGridTimes', [
			'dataStore',
			'seedcodeCalendar',
			'manageSettings',
			'environment',
			manageGridTimes,
		]);

	function calendarInit(
		$q,
		$timeout,
		$rootScope,
		$location,
		$translate,
		calendarOptions,
		urlParameters,
		utilities,
		dataStore,
		manageSettings,
		calendarIO,
		calendarEvents,
		daybackIO,
		seedcodeCalendar,
		manageSchedules,
		manageEventSources,
		manageResources,
		manageGridTimes,
		manageFilters,
		manageMap,
		hash,
		crypt,
		manageTheme,
		environment,
		csvData,
		manageCalendarActions,
		filemakerJS,
		shares,
		fieldFormatting,
		groupSelector
	) {
		var hasEditableSource;
		var preventURLChangeEvent;
		var calendarActions;
		var bookmark;
		var share;
		var loadedScheduleCount = 0;
		var languageDeferred = $q.defer();
		var watchCalendarViewRendered;

		function languageLoaded() {
			return languageDeferred.promise;
		}

		return {
			init: init,
		};
		function init(preventEventLoading, settingsOnly, isShare, callback) {
			var urlParams = getURLParams();
			cleanURL(urlParams); // Clean the contents of the url after getting the data. Prevents us from re-applying the data when navigating app

			var useStaticData =
				_CONFIG.DBK_USE_STATIC_DATA || urlParameters?.staticConfigPath;
			seedcodeCalendar.init('useStaticData', useStaticData);
			// seedcodeCalendar.reset(); //Do not use as this isn't fully fleshed out and can remove data we don't refetch
			// manageEventSources.init();

			//Reset view object as it could be carried over from a different route
			seedcodeCalendar.set('view', null);
			// Reset seedcodeCalendar variables as they should be set again during init
			daybackIO.reset(true);
			calendarEvents.reset();

			//define the functions we want available for calendar and event actions as part of the dbk object
			var actionHelpers = {
				requestThrottle: utilities.requestThrottle,
				updateConfig: utilities.updateConfig,
				scriptURL: utilities.scriptURL,
				showMessage: utilities.showMessage,
				popover: utilities.popover,
				navigate:
					fbk && fbk.settings.sr.algorithm
						? fbk.navigate
						: filemakerJS.goToEvent,
				showEventOnLayout: filemakerJS.showEventOnLayout,
				performFileMakerScript: utilities.performFileMakerScript,
				registerFunctionCall: function (name, functionReference) {
					if (!name || !functionReference) {
						return;
					}
					dbk_fmFunctions[name] = functionReference;
				},
				mutateFilterField: manageSettings.mutateFilterField,
				nameToFilterItem: manageSettings.nameToFilterItem,
				filterFieldSort: manageSettings.filterFieldSort,
				resourceManager: {
					getViewed: manageResources.getViewed,
					updateColumns: manageResources.updateColumns,
					reset: manageResources.reset,
				},
				resetResources: manageResources.reset,
				updateResourceColumns: manageResources.updateColumns,
				manageFilters: {
					clearFilters: manageFilters.clearFilters,
					applyFilterSearch: manageFilters.applyFilterSearch,
				},
				isEventShown: (event) => {
					return manageFilters.isEventShown(event, true);
				},
				toggleCalendar: manageSchedules.selectSchedule,
				eventChanged: calendarIO.eventChanged,
				updateEditEvent: calendarIO.updateEditEvent,
				refreshEditPopover: calendarIO.refreshEditPopover,
				mapManager: manageMap,
				addEvent: function (event) {
					calendarIO.cleanEvent(event);
					seedcodeCalendar
						.get('element')
						.fullCalendar('renderEvent', event);
				},
				addEvents: function (events) {
					calendarIO.cleanEvents(events);
					seedcodeCalendar
						.get('element')
						.fullCalendar('renderEvents', events);
				},
				updateEvent: function (
					event,
					changes,
					revertFunc,
					callback,
					options
				) {
					var translations = $translate.instant([
						'Cannot modify a read only calendar',
					]);
					options = options ? options : {};
					if (!event.schedule.editable) {
						utilities.showMessage(
							translations['Cannot modify a read only calendar']
						);
						return;
					}
					calendarIO.getEventChanges(
						event,
						null,
						revertFunc,
						callback,
						options,
						changes
					);
				},
				createEvent: function (params) {
					// param properties
					// {
					// 	event: an event object with title and start,
					// 	calendarID: a calendar id, not required if "scheduleName" is used,
					// 	calendarName: a calendar name, not required if "scheduleID" is used,
					// 	callback: an optional callback function when process is complete
					// }

					if (!params) {
						return;
					}

					calendarIO.createEvent(
						params.event,
						params.calendarID,
						params.calendarName,
						params.preventWarnings,
						params.renderEvent,
						callback
					);
					function callback(result) {
						if (params.callback) {
							params.callback(result);
						}
					}
				},
				deleteEvent: function (params) {
					// possible params:
					// event - Required if no editEvent object is passed
					// editEvent - should be passed if it exists
					// repetitions - could be 'future', 'all', 'single' single is the default

					var options = {};

					if (!params) {
						params = {};
					}

					if (!params.editEvent && params.event) {
						// Create edit event from event
						params.editEvent = calendarIO.createEditEvent(
							params.event,
							params.event.schedule.fieldMap
						);
					}

					// This is a repeating event so how do we handle repetitions
					if (params.event && params.event.recurringEventID) {
						if (
							params.repetitions &&
							params.repetitions === 'future'
						) {
							options.future = true;
						} else if (
							params.repetitions &&
							params.repetitions === 'all'
						) {
							options.all = true;
						}
						options.confirmed = true;
					}

					// Close any open popovers
					if (params.closePopovers) {
						$rootScope.$broadcast('closePopovers');
					}
					calendarIO.deleteEvent(params.editEvent, callback, options);

					function callback(result) {
						if (params.callback) {
							params.callback(result);
						}
					}
				},
				getRawFilterData: manageSettings.getRawFilterData,
				toggleMultiSelect: calendarIO.toggleMultiSelect,
				localTimeToTimezoneTime: function (date, isAllDay) {
					return $.fullCalendar.createTimezoneTime(date, isAllDay);
				},
				timezoneTimeToLocalTime: function (date, isAllDay) {
					return $.fullCalendar.timezoneTimeToLocalTime(
						date,
						isAllDay
					);
				},
				applyListFilter: function (keyWord, type) {
					manageFilters.applyFilterSearch(
						keyWord,
						type || 'resource',
						true,
						true,
						null
					);
				},
				objectArrayMatch: utilities.objectArrayMatch,
				getCustomFieldIdByName: utilities.getCustomFieldIdByName,
				setEventSortPriority: function (event, sortValue) {
					if (!event) {
						return;
					}

					if (!event.metadata) {
						event.metadata = {};
					}

					event.metadata.sortPriority = {
						value: sortValue,
					};
				},
				setEventSortOverride: function (event, sortValue) {
					if (!event) {
						return;
					}

					if (!event.metadata) {
						event.metadata = {};
					}

					event.metadata.sortOverride = {
						value: sortValue,
					};
				},
				startKioskMode: function (refreshMinutes, preventDateChange) {
					calendarIO.kioskMode(refreshMinutes, preventDateChange);
				},
				getBookmarkList: function (callback) {
					shares.getUserShareList(callback, false);
				},
				observe: utilities.observe,
				getTranslations: function (
					translationTerms,
					translationValues
				) {
					const translations = $translate.instant(
						translationTerms,
						translationValues
					);
					return translations;
				},
				getLanguage: function () {
					const config = seedcodeCalendar.get('config');
					return config.language === 'auto'
						? $translate.preferredLanguage()
						: config.language;
				},
				updateAltView: function (options) {
					const altView = seedcodeCalendar.get('alt-view');
					seedcodeCalendar.init('alt-view', {...altView, ...options});
				},
				applyTheme: function (theme) {
					const config = seedcodeCalendar.get('config');
					config.cssTheme =
						!theme || theme.toLowerCase() === 'dayback'
							? ''
							: theme;
					manageTheme.applyThemeSetting(theme);
				},
			};

			seedcodeCalendar.init('actionHelpers', actionHelpers);

			//Broadcast and set that we are currently loading settings
			seedcodeCalendar.init('loading-settings', true);
			seedcodeCalendar.init('schedulesLoaded', false);
			seedcodeCalendar.init('previousViewRenderSettings', {}, true);
			seedcodeCalendar.init('changedFilters', [], true);
			seedcodeCalendar.init('changedSchedules', [], true);
			var localizeDateFormat = $.fullCalendar.localizeDateFormat; //Import function from fullcalendar
			var touchSupported = environment.touchSupported;

			//For initial event let's set up a watcher $on('eventsRendered') then re-render just that event showing the popover then clear the $on This can all be handled in the url watcher as long as the url watcher is called before events are rendered.
			var initialEvent = urlParams.id; //Load up an event id that could have been passed in the url that we can show when the calendar loads
			var translations;

			var shareSettingsOverride = {};

			//Remove our cookies that store setting information
			if (
				$location.search().clearUserSettings === '1' ||
				$location.search().clearUserSettings === 'True'
			) {
				dataStore.clearAllSavedStates();
				$location.search('clearUserSettings', false);
			}

			//Filemaker passes this to prevent calendar from rendering - Used for clearing cache
			//Keep this otherwise filemaker will try and render the calendar in hidden webviewers which could slow things down
			if ($location.search().preventEventLoad) {
				return;
			}

			//Initialize and load user
			daybackIO.loadUser(
				//Success
				function (user) {
					//can we get user settings here?
					seedcodeCalendar.init('user', user);
					//Save user to session
					dataStore.initializeDataStoreCache(
						//Success
						function () {
							dataStore.saveState('user', user.auth.uid, true);
							//Get and apply user settings
							manageSettings.getUserSettings(applyUserSettings);
						},
						isShare
					);
				},
				//Rejected - Load user reject callback
				function (path) {
					seedcodeCalendar.init('loading-settings', false);
					if (path) {
						$timeout(function () {
							$location.path(path);
						}, 0);
					}
				}
			);

			function applyUserSettings(userSettings) {
				// Init passed in user settings. These are device agnostic settings for the user like the default calendar for new events
				seedcodeCalendar.init('userSettings', userSettings);

				// var permanentSavePath = dataStore.getPermanentSavePath();
				//Look for platform specific settings break out device specific settings and save to local storage savestate for each one
				// if (userSettings && permanentSavePath) {
				// 	for (var property in userSettings[permanentSavePath]) {
				// 		dataStore.saveState(property, userSettings[permanentSavePath][property]);
				// 	}
				// }

				manageSettings.initializeOnlineStatus(isShare);

				//Check for the last group for the user and initialize as needed
				const platform = utilities.getDBKPlatform();
				const user = seedcodeCalendar.get('user');
				const storedGroupSelection = dataStore.getState('groupId');
				const lastGroupId = storedGroupSelection
					? dataStore.getState('groupId')
					: user.group.id;

				// Set primary group so event if we are changing groups we know
				// the original group id
				user.primaryGroup = {...user.group};

				if (platform !== 'dbkfmjs' && platform !== 'dbkfmwd') {
					if (
						user.additionalGroups &&
						user.additionalGroups[lastGroupId]
					) {
						//if last stored group is a valid group and user is a member
						//then set this group for this session
						user.group = user.additionalGroups[lastGroupId];
						dataStore.saveState('groupId', lastGroupId);
					} else if (user.additionalGroups && !storedGroupSelection) {
						dataStore.saveState('groupId', user.group.id);
						groupSelector.changeGroupPopover(() => {
							isValidParamBookmarkID(
								urlParams.bookmarkID,
								processBookmark
							);
						});
						return;
					}
				}

				//Get Bookmark data then get and apply admin settings
				isValidParamBookmarkID(urlParams.bookmarkID, processBookmark);

				function processBookmark(result) {
					bookmark = result;
					//Get and apply admin settings
					manageSettings.getSettings(applySettings);
				}
			}

			//Functions for initializing
			function applySettings(settings) {
				var defaults = seedcodeCalendar.get('defaults');
				share = seedcodeCalendar.get('share');
				var shareSettings = bookmark
					? bookmark.settings
					: share
						? share.settings
						: null;

				seedcodeCalendar.init('settings', settings);

				// Initialize local settings if configured
				dataStore.initializeLocalSettings(processSettings, isShare);

				function processSettings() {
					var settings = seedcodeCalendar.get('settings');
					//Create config
					var config = {};

					//Initialize variables for tracking todays date
					var redrawTimeout;
					config.todayDate = moment();

					//Record loaded timezone offset and date so we can compare for changes later
					config.timezoneDateCompare =
						config.todayDate.format('YYYY-MM-DD');
					config.timezoneOffsetCompare = moment(
						config.timezoneDateCompare
					).utcOffset();

					//Set share status to config because we will need to know this right away
					config.isShare = isShare;

					//Set save state preference from url param
					if (urlParams.saveState) {
						config.saveState =
							urlParams.saveState === 'false' ? false : true;
					}

					//Apply admin defined settings to config
					manageSettings.settingsToConfig(config);

					//Apply user defined settings to config
					manageSettings.userSettingsToConfig(config);
					//Update breakout info for share
					if (config.isShare) {
						config.shareBreakout = config.breakout
							? JSON.parse(JSON.stringify(config.breakout))
							: null;
						config.shareBreakoutField = config.breakoutField
							? config.breakoutField
							: null;
					}

					//Restore analytics from local storage
					config.showMeasure = dataStore.getState('showMeasure');
					try {
						config.showMeasure = config.showMeasure
							? JSON.parse(config.showMeasure)
							: null;
					} catch (error) {
						config.showMeasure = null;
					}

					//parameters for lookups
					config.lookups = {};
					if (
						(urlParams.contactID && urlParams.contactName) ||
						(urlParams.projectID && urlParams.projectName)
					) {
						if (urlParams.contactID) {
							config.lookups.contactID =
								urlParams.contactID === 'null'
									? ''
									: urlParams.contactID;
							config.lookups.contactName =
								urlParams.contactName === 'null'
									? ''
									: urlParams.contactName;
						}
						if (urlParams.projectID) {
							config.lookups.projectID =
								urlParams.projectID === 'null'
									? ''
									: urlParams.projectID;
							config.lookups.projectName =
								urlParams.projectName === 'null'
									? ''
									: urlParams.projectName;
						}
					}

					// Set primary calendar if there is an admin override set
					if (config.primaryCalendarOverride) {
						config.primaryCalendar = config.primaryCalendarOverride;
					}

					//Not used by fullcalendar
					config.hasEditableSource = true; //hasEditableSource,
					config.databaseDateFormat =
						settings.databaseDateFormat ||
						defaults.databaseDateFormat;
					//Used by fullcalendar
					//config.editable = true;
					if (
						isValidParamDate(
							urlParams.date || $location.search().date
						)
					) {
						config.defaultDate =
							urlParams.date || $location.search().date;
						shareSettingsOverride.defaultDate = config.defaultDate;
					} else {
						config.defaultDate =
							config.snapToToday &&
							dataStore.getState('lastRun') !==
								defaults.defaultDate.format('YYYY-MM-DD')
								? defaults.defaultDate
								: dataStore.getState('date') ||
									settings.defaultDate ||
									defaults.defaultDate;
					}

					// Store the date that the calendar was last loaded
					dataStore.saveState(
						'lastRun',
						defaults.defaultDate.format('YYYY-MM-DD')
					);

					//Check to see if today highlight needs to be updated
					checkTodayHighlight();
					$rootScope.$on('viewRendered', function () {
						clearTimeout(redrawTimeout);
						checkTodayHighlight();
					});
					function checkTodayHighlight() {
						var preventRefresh;
						var refreshDelay;
						var view = seedcodeCalendar.get('view');
						var dateCheck;

						clearTimeout(redrawTimeout);

						// Don't refresh if a popover is open or an event is being dragged or resized
						if (
							!view ||
							(config &&
								config.status &&
								(config.status.preventDrag ||
									config.status.isDragging))
						) {
							refreshDelay = 5000;
							preventRefresh = true;
						} else {
							dateCheck =
								$.fullCalendar.createTimezoneTime(moment());
							//Set next time to check just after midnight
							refreshDelay =
								dateCheck.clone().endOf('day') -
								dateCheck +
								1000;
						}

						if (!preventRefresh && view) {
							if (
								config.todayDate &&
								!moment(config.todayDate).isSame(
									dateCheck,
									'day'
								) &&
								dateCheck.isSameOrAfter(view.start, 'day') &&
								dateCheck.isSameOrBefore(view.end, 'day')
							) {
								config.todayDate = dateCheck;
								// Redraw calendar to update today highlight
								view.calendar.redraw();
							}
						}
						redrawTimeout = window.setTimeout(
							checkTodayHighlight,
							refreshDelay
						);
					}

					if (
						isValidParamView(
							urlParams.view ||
								$location.search().mode ||
								$location.search().view
						)
					) {
						config.defaultView =
							urlParams.view ||
							$location.search().mode ||
							$location.search().view;
						shareSettingsOverride.defaultView = config.defaultView;
					} else {
						config.defaultView =
							dataStore.getState('view') || config.view;
					}

					//Apply view specific settings to config
					manageSettings.viewSettingsToConfig(config);

					//Initialize config
					seedcodeCalendar.init('config', config);

					//Set kiosk mode
					if (urlParameters.kiosk) {
						calendarIO.kioskMode(urlParameters.kiosk);
					}

					//Apply built in themes
					manageTheme.applyThemeSetting(config.cssTheme);

					//Get and apply custom css / theme
					if (!manageTheme.getThemeStyles()) {
						manageTheme.getSavedTheme();
					}

					var memberSettingsLoaded = loadMemberSettings();

					//Set localizations --------------->
					//Language
					//Set language to 2 digit lang code
					var language =
						config.language === 'auto'
							? $translate.preferredLanguage()
							: config.language;

					var locale =
						config.locale === 'auto'
							? calendarOptions.languageWithSub
							: config.locale;

					//Set locale (language) using 2 - 4 character language key ie en, en-au, fr, de etc..
					config.lang = calendarOptions.languageWithSub;
					moment.locale(locale);

					//Set local timezone. This is used if the timzeone is switched so we know what to switch back to
					config.localTimezone = moment.tz.guess();

					//Set date format
					config.dateStringFormat =
						!config.dateStringFormat ||
						config.dateStringFormat === 'auto'
							? localizeDateFormat(defaults.dateStringFormat)
							: config.dateStringFormat;
					config.dateStringShortFormat =
						!config.dateStringFormat ||
						config.dateStringShortFormat === 'auto'
							? localizeDateFormat(defaults.dateStringShortFormat)
							: config.dateStringShortFormat;

					//Set time format
					config.timeFormat =
						!config.timeFormat || config.timeFormat === 'auto'
							? utilities.getLocalTimeFormat()
							: config.timeFormat;

					//Apply lanuage to angular translate
					calendarOptions.loadCustomLanguage = true;
					$translate.use(language).then(function (data) {
						if (!$translate.proposedLanguage()) {
							$translate.refresh(language).then(function (data) {
								applyTranslations();
							});
						} else {
							applyTranslations();
						}

						function applyTranslations() {
							$translate([
								'New_Event_Title',
								'All_Day',
								'Resource',
								'more',
								'Week_Number_Header',
								'Week',
								'weeks',
								'Week of',
								'day',
								'days',
								'Today',
								'Q',
							]).then(function (translated) {
								translations = translated || {};
								languageDeferred.resolve(translations);
							});
						}
					});

					//Exit if we are only needing settings
					if (settingsOnly) {
						if (callback) {
							callback(config);
						}
						//Settings have now been fully loaded and we are ready to load anything that relies on them
						seedcodeCalendar.init('loading-settings', false);
						return;
					}

					//Load sidebar settings
					var sidebar = {};

					//Set URL API settings
					if (urlParams.sidebarShow) {
						config.showSidebar =
							urlParams.sidebarShow === 'false' ? false : true;
					}
					if (urlParams.sidebarLock) {
						config.lockSidebar =
							urlParams.sidebarLock === 'false' ? false : true;
					}

					//Pull sidebar status from share
					if (isShare && share.sidebar) {
						config.showSidebar = share.sidebar.show;
						sidebar = share.sidebar;
					} else if (bookmark && bookmark.sidebar) {
						config.showSidebar = bookmark.sidebar.show;
						sidebar = bookmark.sidebar;
					} else {
						sidebar = {
							view:
								dataStore.getState('sidebarTab') ||
								config.defaultSidebarTab ||
								'calendars',
							show: config.showSidebar,
						};
					}

					//Update current config to match bookmark
					if (isShare && share.settings) {
						manageSettings.applyShareSettings(
							config,
							share,
							shareSettingsOverride
						);
					} else if (bookmark && bookmark.settings) {
						manageSettings.applyShareSettings(config, bookmark);
					}

					dataStore.saveState('showSidebar', config.showSidebar);
					sidebar.show =
						touchSupported || environment.isExtension
							? false
							: sidebar.show;

					//Set sidebar settings and broadcast change
					seedcodeCalendar.init('sidebar', sidebar);

					//Set multi-select object
					seedcodeCalendar.init('multiSelect', config.multiSelect);

					//Broadcast to show analytics if necessary
					if (config.showMeasure && config.showMeasure.status) {
						$rootScope.$broadcast('calendarInfo', true);
					}

					//Initialize alt-view if necessary
					if (urlParams.unscheduledShow) {
						config.showAltView =
							urlParams.unscheduledShow === 'false'
								? false
								: true;
					}

					if (urlParams.unscheduledFilter) {
						config.unscheduledFilter =
							urlParams.unscheduledFilter.toLowerCase() === 'null'
								? ''
								: urlParams.unscheduledFilter;
					}

					seedcodeCalendar.init('alt-view', {
						enabled:
							config.unscheduledEnabled ||
							(config.mapActivated && config.mapEnabled),
						show:
							(config.unscheduledEnabled ||
								(config.mapActivated && config.mapEnabled)) &&
							config.showAltView,
						type:
							(!config.mapActivated || !config.mapEnabled) &&
							config.altViewType === 'map'
								? 'unscheduled'
								: !config.unscheduledEnabled &&
									  config.altViewType === 'unscheduled'
									? 'map'
									: config.altViewType,
						width: config.altViewWidth,
					});

					//Once member settings are loaded we can activate the application and get sources
					memberSettingsLoaded.then(getCalendarActions);

					function getCalendarActions() {
						//Load calendar actionResult
						manageSettings.getCalendarActions(
							function (actionResult) {
								seedcodeCalendar.init(
									'calendarActions',
									manageCalendarActions.assignValidCalendarActions(
										actionResult
									)
								);
								calendarActions =
									seedcodeCalendar.get('calendarActions');
								if (
									calendarActions &&
									calendarActions.sourcesFetched
								) {
									const actionCallbacks = {
										confirm: function () {
											activateApp();
										},
										cancel: function () {
											// We assume that cancel means actions are taking over so stop loading settings
											// Wrap in evalAsync to trigger digest cycle as this is coming from async operation
											$rootScope.$evalAsync(() => {
												seedcodeCalendar.init(
													'loading-settings',
													false
												);
											});
										},
									};

									//Run load actions
									const preventDefaultResult =
										manageCalendarActions.runCalendarActions(
											calendarActions.startup,
											actionCallbacks
										);
									if (preventDefaultResult) {
										return;
									}
								}
								activateApp();
							}
						);
					}

					function activateApp() {
						//Activate the program using the hash
						if (!$location.search().preventEventLoad && !isShare) {
							crypt.verifyHash(
								settings.activationHash,
								null,
								null,
								function (verifiedHash) {
									//Set activation state from hash type (trial, subscription, inactive)
									config.activationState = verifiedHash.type;
									//Get sources
									manageSettings.getSources(applySources);
								}
							);
						} else {
							manageSettings.getSources(applySources);
						}
					}
				}
			}

			function applySources(sources) {
				var config = seedcodeCalendar.get('config');
				var preventDefaultResult;
				var actionCallbacks;
				var sourceTemplateMatch;
				var groupAuthSources = {};

				// Initialize source templates first
				manageEventSources.initializeTemplates();

				var sourceTemplates = seedcodeCalendar.init(
					'sourceTemplates',
					manageEventSources.getTemplates()
				);

				for (var i = sources.length - 1; i >= 0; i--) {
					sourceTemplateMatch = false;
					// Create list of source types that are set to allow group / shared authentication
					if (sources[i].groupAuthAvailable) {
						groupAuthSources[sources[i].sourceTypeID] = true;
					}
					// Make sure to initialize a status object so we can set things like enabled or loading
					for (var ii = 0; ii < sourceTemplates.length; ii++) {
						// Check if source is enabled
						if (
							sources[i].sourceTypeID === sourceTemplates[ii].id
						) {
							sourceTemplateMatch = true;
							sources[i].sourceTypeEnabled =
								sourceTemplates[ii] &&
								sourceTemplates[ii].status &&
								sourceTemplates[ii].status.enabled;

							// Set config item to record if google calendar is available. Currently used for hide repetitions functionality
							if (sources[i].sourceTypeID) {
								config.hasGoogleCalendar =
									sources[i].sourceTypeEnabled;
							}
							break;
						}
					}
					// If there is no source template (might be because it's not available on this platform)
					if (!sourceTemplateMatch) {
						sources.splice(i, 1);
						continue;
					}
				}

				// Create matrix / dictionary of the sources that allow shared / group auth
				seedcodeCalendar.init(
					'groupAuthSources',
					groupAuthSources,
					true
				);

				seedcodeCalendar.init('sources', sources);

				//Run on load calendar actions
				if (calendarActions && calendarActions.sourcesFetched) {
					actionCallbacks = {
						confirm: function () {
							//Check for bookmark, then get and apply resources
							checkForBookmark(function () {
								manageSettings.getResources(applyResources);
							});
						},
						cancel: function () {
							//Logic for a cancel function could go here
						},
					};

					//Run load actions
					preventDefaultResult =
						manageCalendarActions.runCalendarActions(
							calendarActions.sourcesFetched,
							actionCallbacks
						);
					if (preventDefaultResult) {
						return;
					}
				}

				//Check for bookmark, then get and apply resources
				checkForBookmark(function () {
					manageSettings.getResources(applyResources);
				});

				function checkForBookmark(callback) {
					var bookmarkID = seedcodeCalendar.get('bookmarkID');
					seedcodeCalendar.init('bookmarkID');
					if (bookmarkID && !urlParams.bookmarkID) {
						//apply share settings and apply sidebar settings from share and update bookmark object
						isValidParamBookmarkID(bookmarkID, function (result) {
							bookmark = result;
							if (bookmark) {
								processBookmark(callback);
							} else {
								callback();
							}
						});
					} else {
						callback();
					}
				}

				function processBookmark(callback) {
					//Pull sidebar status from share
					var sidebar = {};
					if (bookmark.sidebar) {
						config.showSidebar = bookmark.sidebar.show;
						sidebar = bookmark.sidebar;
					}

					//Update current config to match bookmark
					if (bookmark.settings) {
						manageSettings.applyShareSettings(config, bookmark);
					}

					dataStore.saveState('showSidebar', config.showSidebar);
					sidebar.show =
						touchSupported || environment.isExtension
							? false
							: sidebar.show;

					//Set sidebar settings and broadcast change
					seedcodeCalendar.init('sidebar', sidebar);

					//Set multi-select object
					seedcodeCalendar.init('multiSelect', config.multiSelect);

					//Broadcast to show analytics if necessary
					if (config.showMeasure && config.showMeasure.status) {
						$rootScope.$broadcast('calendarInfo', true);
					}

					callback();
				}
			}

			function applyResources(resources) {
				var preventDefaultResult;
				var actionCallbacks;
				var config = seedcodeCalendar.get('config');
				var originalResources = JSON.stringify(resources);

				seedcodeCalendar.init('resources', resources);

				//Run on load calendar actions
				if (calendarActions && calendarActions.resourcesFetched) {
					actionCallbacks = {
						confirm: function () {
							//Complete resources
							completeApplyResources();
						},
						cancel: function () {
							//Logic for a cancel function could go here
						},
					};

					//Run load actions
					preventDefaultResult =
						manageCalendarActions.runCalendarActions(
							calendarActions.resourcesFetched,
							actionCallbacks
						);
					if (preventDefaultResult) {
						return;
					}
				}
				completeApplyResources();

				function completeApplyResources() {
					resources = seedcodeCalendar.get('resources');

					// Resource list has changed from an action so we need to clean it again
					if (JSON.stringify(resources) !== originalResources) {
						seedcodeCalendar.init(
							'resources',
							manageSettings.mutateFilterFieldList(
								seedcodeCalendar.get('resources'),
								null,
								true,
								null,
								null,
								null,
								true
							)
						);
					}
					//Restore selected filters
					manageFilters.restoreFilters(
						'resources',
						urlParams.filterResources,
						null,
						share ? share : bookmark
					);

					//use provided resourceListFilter from url if it exists
					var resourceListFilter = config.resourceListFilter;
					if (urlParams.resourceListFilter) {
						resourceListFilter =
							urlParams.resourceListFilter === 'null'
								? ''
								: urlParams.resourceListFilter;
					}

					manageFilters.applyFilterSearch(
						resourceListFilter,
						'resource',
						false,
						false,
						null
					);

					manageResources.init(config.resourceColumns);

					//Get and apply statuses
					manageSettings.getStatuses(applyStatuses);
				}
			}

			function applyStatuses(statuses) {
				var preventDefaultResult;
				var actionCallbacks;
				var settings = seedcodeCalendar.get('settings');
				//We use the config object to initialize our statuses so we probably want to do all of this after our config init
				seedcodeCalendar.init('statuses', statuses);

				//Run on load calendar actions
				if (calendarActions && calendarActions.statusesFetched) {
					actionCallbacks = {
						confirm: function () {
							//Complete resources
							completeApplyStatuses();
						},
						cancel: function () {
							//Logic for a cancel function could go here
						},
					};

					//Run load actions
					preventDefaultResult =
						manageCalendarActions.runCalendarActions(
							calendarActions.statusesFetched,
							actionCallbacks
						);
					if (preventDefaultResult) {
						return;
					}
				}

				completeApplyStatuses();

				function completeApplyStatuses() {
					//Restore selected filters
					manageFilters.restoreFilters(
						'statuses',
						urlParams.filterStatuses,
						settings.statusPreFilter,
						share ? share : bookmark
					);

					//Load and filter text search
					manageFilters.restoreFilters(
						'text',
						urlParams.filterText,
						settings.textPreFilter,
						share ? share : bookmark
					);

					applyProjects();
				}
			}

			function applyProjects() {
				var preventDefaultResult;
				var actionCallbacks;

				var config = seedcodeCalendar.get('config');

				//Load and filter project and contacts
				var projects = manageFilters.createAdvanceFilter(
					urlParams.filterProjects,
					'projects'
				);
				seedcodeCalendar.init('projects', projects);

				if (projects && projects.length) {
					config.hasProjects = true;
				}

				//Run on load calendar actions
				if (calendarActions && calendarActions.projectsFetched) {
					actionCallbacks = {
						confirm: function () {
							//Get and apply resources
							completeApplyProjects();
						},
						cancel: function () {
							//Logic for a cancel function could go here
						},
					};

					//Run load actions
					preventDefaultResult =
						manageCalendarActions.runCalendarActions(
							calendarActions.projectsFetched,
							actionCallbacks
						);
					if (preventDefaultResult) {
						return;
					}
				}

				completeApplyProjects();

				function completeApplyProjects() {
					manageFilters.restoreFilters(
						'projects',
						urlParams.filterProjects,
						null,
						share ? share : bookmark
					);
					applyContacts();
				}
			}

			function applyContacts() {
				var preventDefaultResult;
				var actionCallbacks;

				var config = seedcodeCalendar.get('config');

				//Load and filter contacts
				var contacts = manageFilters.createAdvanceFilter(
					urlParams.filterContacts,
					'contacts'
				);
				seedcodeCalendar.init('contacts', contacts);

				if (contacts && contacts.length) {
					config.hasContacts = true;
				}

				//Run on load calendar actions
				if (calendarActions && calendarActions.contactsFetched) {
					actionCallbacks = {
						confirm: function () {
							//Get and apply resources
							completeApplyContacts();
						},
						cancel: function () {
							//Logic for a cancel function could go here
						},
					};

					//Run load actions
					preventDefaultResult =
						manageCalendarActions.runCalendarActions(
							calendarActions.contactsFetched,
							actionCallbacks
						);
					if (preventDefaultResult) {
						return;
					}
				}

				completeApplyContacts();

				function completeApplyContacts() {
					manageFilters.restoreFilters(
						'contacts',
						urlParams.filterContacts,
						null,
						share ? share : bookmark
					);

					//Apply remaining config after ensuring we have language translations
					languageLoaded().then(function (translations) {
						applyConfig();
					});
				}
			}

			function loadMemberSettings() {
				var deferred = $q.defer();
				var config = seedcodeCalendar.get('config');
				var callbackCount = 2;
				//load group member settings
				manageSettings.getGroupInfo(null, (result) => {
					config.groupName = result.name;
					config.groupAccess = result.access;
					callbackCount--;
					if (callbackCount <= 0) {
						deferred.resolve();
					}
				});

				manageSettings.getGroupMemberData(applyMemberSettings);

				function applyMemberSettings(member) {
					var config = seedcodeCalendar.get('config');
					var user = daybackIO.getUser();
					if (!member) {
						//If we didn't return a valid member we should create one based off of the recorded member ID. This shouldn't happen but if the user has an assocciated Group and member ID something went wrong and we should fix it.
						var members = {};
						var memberID = user.group.memberID;
						if (memberID) {
							member = daybackIO.createMemberData(
								memberID,
								user.email,
								user.id
							);
							members[memberID] = member;
							//Add the newly created member to the group
							manageSettings.setGroupMembers(members, null);
						}
					}
					if (member) {
						//Check salesforce user profile and update member settings
						if (fbk.isSalesforce()) {
							var sfAdmin =
								fbk.settings.userProfile.toLowerCase() ===
									'system administrator' ||
								fbk.settings.userProfile.toLowerCase() ===
									'amministratore del sistema' || //Italian
								fbk.settings.userProfile.toLowerCase() ===
									'systemadministrator' || //German
								fbk.settings.userProfile.toLowerCase() ===
									'administrateur système'; //French

							//Get admin overrides
							var account = member.account
								? member.account.toLowerCase()
								: null;
							var isForceAdmin;

							if (config.adminAccountList && account) {
								for (
									var i = 0;
									i < config.adminAccountList.length;
									i++
								) {
									if (
										config.adminAccountList[i] === account
									) {
										isForceAdmin = true;
										break;
									}
								}
							}

							//Set salesforce specific member data
							if (member.admin !== sfAdmin) {
								manageSettings.setGroupMemberData(
									'admin',
									sfAdmin,
									null
								);
								member.admin = sfAdmin;
							}

							//Override member admin if they are part of the admin account list
							if (isForceAdmin) {
								member.admin = true;
							}

							//Update member info with SF Data
							member.email = fbk.settings.userInfo.accountEmail;
							member.firstName =
								fbk.settings.userInfo.accountFirstName;
							member.lastName =
								fbk.settings.userInfo.accountLastName;
						} else {
							if (member.active === false) {
								// This member is not active so display dialog
								crypt.inactiveAccount();
							}
						}

						//Update member data
						member.email = !member.email
							? member.account
							: member.email;

						config.account = member.account;
						config.userID = user.id;
						config.groupID = user.group.id;
						config.accountName = member.firstName
							? member.firstName + ' ' + member.lastName
							: member.account;
						config.email = member.email;
						config.admin = member.admin;
						config.firstName = member.firstName;
						config.lastName = member.lastName;
						config.manageFilters = member.manageFilters;
						config.readOnly = member.readOnly;
						config.preventSharing = member.allowSharing === false;
						config.userGroupAuthSources =
							member.userGroupAuthSources || {};

						callbackCount--;
						if (callbackCount <= 0) {
							deferred.resolve();
						}

						var sessionUser =
							user && user.group
								? user.group.id + '-' + user.id
								: '';

						config.sessionUser = sessionUser;

						// Update last sign in property
						// Make sure this comes after setting the group and user to the config object
						manageSettings.setSignInDate();
					}
				}
				return deferred.promise;
			}

			function applyConfig() {
				var config = seedcodeCalendar.get('config');
				var settings = seedcodeCalendar.get('settings');
				var defaults = seedcodeCalendar.get('defaults');
				var preventDefaultResult;
				var actionCallbacks;
				var dateFormat = localizeDateFormat('M/D');

				//Update view day ranges if a range property is set in the url
				updateViewDayRange(config, urlParams.range);

				//Check if this is a share, if it is override certain settings
				daybackIO.createShareConfig(config);

				//Settings based on current config items and manual overrides
				config.droppable = true;
				config.eventStartEditable = config.readOnly ? false : true;
				config.eventDurationEditable = config.readOnly ? false : true;

				config.newEventTitleText =
					translations['New_Event_Title'] || 'New Event';
				config.allDayText = translations['All_Day'] || 'All Day';
				config.resourceText = translations['Resource'] || 'Resource';
				config.moreEventsText = translations['more'] || 'more';
				config.weekNumberTitle =
					translations['Week_Number_Header'] || 'W';
				config.weekText = translations['Week'] || 'Week';
				config.weeksText = translations['weeks'] || 'weeks';
				config.weekOfText = translations['Week of'] || 'Week of';
				config.dayText = translations['day'] || 'day';
				config.daysText = translations['days'] || 'days';
				config.todayText = translations['Today'] || 'Today';
				config.quarterText = translations['Q'] || 'Q';

				//Using ISO weeks now because that is an international format but we could change this to a calc that uses regular week numbers in US and ISO for everywhere else
				config.weekNumberCalculation = 'ISO'; //local, iso, or a function can be passed here. ISO is the international standard so we are using that

				//Use our specified time format as the axis format
				config.axisFormat = config.timeFormat;

				config.forceEventDuration = true;
				config.timezone = 'local';

				//Set horizon slider and make sure if it isn't set we use the older method of "horizonDays"
				config.horizonSlider = manageSettings.cleanHorizonSlider(
					config.horizonSlider,
					config.horizonDays
				);

				config.rowDateFormat = {
					days: {
						day: 'dd',
						date: dateFormat,
					},
				};

				config.columnFormat = {
					month: {
						lg: {
							breakPoint: 125,
							format: 'dddd',
							date: dateFormat,
						},
						md: {
							breakPoint: 50,
							format: 'ddd',
							date: dateFormat,
						},
						sm: {
							breakPoint: 0,
							format: 'dd',
							date: 'D',
						},
					},
					week: {
						lg: {
							breakPoint: 135,
							format: 'dddd, D',
							date: dateFormat,
						},
						md: {
							breakPoint: 80,
							format: 'ddd D',
							date: dateFormat,
						},
						sm: {
							breakPoint: 50,
							format: 'dd D',
							date: dateFormat,
						},
						xs: {
							breakPoint: 0,
							format: 'dd',
							date: 'D',
						},
					},
					day: '',
					resource: {
						lg: {
							breakPoint: 135,
							format: 'dddd, D',
							date: dateFormat,
						},
						md: {
							breakPoint: 80,
							format: 'ddd D',
							date: dateFormat,
						},
						sm: {
							breakPoint: 50,
							format: 'dd D',
							date: dateFormat,
						},
						xs: {
							breakPoint: 0,
							format: 'D',
							date: 'D',
						},
					},
				};
				config.header = {
					left: '',
					center: '',
					right: '',
				};

				//Core title formats
				config.titleFormat = {
					year:
						!settings.titleYearFormat ||
						settings.titleYearFormat === 'auto'
							? localizeDateFormat(defaults.titleFormat.year)
							: settings.titleYearFormat ||
								defaults.titleFormat.year,
					month:
						!settings.titleMonthFormat ||
						settings.titleMonthFormat === 'auto'
							? localizeDateFormat(defaults.titleFormat.month)
							: settings.titleMonthFormat ||
								defaults.titleFormat.month,
					week:
						!settings.titleWeekFormat ||
						settings.titleWeekFormat === 'auto'
							? defaults.titleFormat.week
							: settings.titleWeekFormat ||
								defaults.titleFormat.week,
					day:
						!settings.titleDayFormat ||
						settings.titleDayFormat === 'auto'
							? defaults.titleFormat.day
							: settings.titleDayFormat ||
								defaults.titleFormat.day,
				};

				//If weeks are meant to be shown as week numbers we need to override previous settings
				if (
					settings.titleWeekFormat === 'w' ||
					settings.titleWeekFormat === 'W'
				) {
					config.titleFormat.week =
						'[' +
						translations['Week'] +
						'] ' +
						settings.titleWeekFormat;
				}
				//Title formats based on
				config.titleFormat.resource = [
					config.titleFormat.day,
					config.titleFormat.week,
				];
				config.titleFormat.days = [
					config.titleFormat.day,
					config.titleFormat.week,
				];
				config.titleFormat.horizon = [
					config.titleFormat.day,
					config.titleFormat.week,
					config.titleFormat.month,
					config.titleFormat.year,
				];

				config.breakoutHeader = true;
				config.eventBorderColor = 'rgb(0,0,0)';
				//Resources
				config.resourceColumns = manageResources.getResourceColumns();
				config.resources = manageResources.getViewed();
				config.resourcesAll = manageResources.getFiltered;

				if (urlParams.resourceDays) {
					config.resourceDays = Number(urlParams.resourceDays);
				}

				if (urlParams.weekCount) {
					config.weekCount = Number(urlParams.weekCount);
				}

				//Statuses
				config.statusesAll = function () {
					return manageFilters.getEnabledFilterItems(
						'statuses',
						false
					);
				};

				//Contacts
				config.contactsAll = function () {
					return manageFilters.getEnabledFilterItems(
						'contacts',
						true
					);
				};

				//Projects
				config.projectsAll = function () {
					return manageFilters.getEnabledFilterItems(
						'projects',
						true
					);
				};

				//Calendars
				config.schedulesAll = function () {
					var breakoutField = config.horizonBreakoutField;
					var share = seedcodeCalendar.get('share');
					var breakoutList = [];
					var breakoutDataMatrix = {};
					var schedules;
					var sharedSchedules;

					if (breakoutField !== 'schedule') {
						return;
					}

					if (config.isShare) {
						schedules = [];
						sharedSchedules = share.schedules;
						for (var property in sharedSchedules) {
							schedules.push(sharedSchedules[property]);
						}
					} else {
						schedules = seedcodeCalendar.get('schedules');
					}

					if (!schedules) {
						// If there are no schedules it is because we are loading this
						// before we have received schedules (probably first load)
						return;
					}

					for (var i = 0; i < schedules.length; i++) {
						if (
							schedules[i].status &&
							schedules[i].status.selected &&
							!schedules[i].isMeasureOnly &&
							!schedules[i].isMapOnly
						) {
							breakoutDataMatrix[schedules[i].name] = {
								id: schedules[i].id,
								name: schedules[i].name,
								color: schedules[i].backgroundColor,
								folderName: config.isShare
									? manageEventSources.getTemplate(
											schedules[i].sourceTypeID
										).name
									: schedules[i].source.name,
							};
						}
					}

					breakoutList = manageSettings.mutateFilterFieldList(
						breakoutDataMatrix,
						null,
						true,
						null,
						null,
						'folderName'
					);

					manageFilters.restoreSessionCollapseState(
						breakoutList,
						breakoutField,
						config.isShare && config.scheduleCollapsed
							? JSON.parse(config.scheduleCollapsed)
							: null
					);

					seedcodeCalendar.init(
						'breakoutSchedules',
						breakoutList,
						true
					);

					return breakoutList;
				};

				config.customFieldsAll = function (events) {
					var view = seedcodeCalendar.get('view');
					var shareSources = seedcodeCalendar.get('shareSources');
					var share = seedcodeCalendar.get('share');
					var defaultLabelMap =
						seedcodeCalendar.get('defaultLabelMap');
					var breakoutField = config.horizonBreakoutField;
					var breakout = config.breakout;
					var breakoutDataMatrix = {};
					var breakoutData;
					var breakoutCustomField;
					var breakoutList = [];
					var customFields;
					var customField;
					var labelMap;
					var labelMapOverride;
					var labelOverrideMatch;
					var unusedMap;
					var label;
					var mutate;
					var scheduleID;
					var schedule;
					var shareSchedules;
					var shareSource;

					var scheduleMatrix = {};

					var sortField = 'display';

					if (
						breakout
						// breakoutField &&
						// breakoutField !== 'resource' &&
						// breakoutField !== 'status' &&
						// breakoutField !== 'project' &&
						// breakoutField !== 'schedule'
					) {
						for (var i = 0; i < events.length; i++) {
							labelOverrideMatch = false;
							labelMapOverride = events[i].labelMapOverride;

							if (events[i].breakout) {
								// Reset event breakout data that was set from a label override
								events[i].breakout = null;
							}

							if (config.isShare) {
								shareSource = shareSources[
									events[i].shareScheduleID
								]
									? shareSources[events[i].shareScheduleID]
									: shareSources[events[i].shareSourceID];
								shareSchedules = share.schedules;
								customFields =
									utilities.getValidShareCustomFields(
										shareSource,
										events[i]
									);
								scheduleID = events[i].shareScheduleID;
								schedule = shareSchedules
									? shareSchedules[
											utilities.stringToID(scheduleID)
										]
									: null;
								labelMap = utilities.getShareScheduleProperties(
									shareSource,
									scheduleID,
									'labelMap'
								);
								unusedMap =
									utilities.getShareScheduleProperties(
										shareSource,
										scheduleID,
										'unusedMap'
									);
							} else {
								schedule = events[i].schedule;
								scheduleID = schedule.id;
								customFields = schedule.customFields;
								labelMap = schedule.labelMap;
								unusedMap = schedule.unusedMap;
							}

							// Set Schedule data for easy retrieval
							// Custom field match
							if (
								customFields &&
								(!scheduleMatrix[scheduleID] ||
									!scheduleMatrix[scheduleID].breakout ||
									scheduleMatrix[scheduleID].breakout
										.label !== breakout.label)
							) {
								for (var property in customFields) {
									if (
										breakout.label ===
										customFields[property].name
									) {
										//Matched to field
										scheduleMatrix[scheduleID] = property;
										schedule.breakout = {
											label: breakout.label,
											field: property,
										};
										customField = customFields[property];
										break;
									}
								}
							}

							// Label override match
							if (labelMapOverride) {
								for (var property in labelMapOverride) {
									if (
										(!unusedMap || !unusedMap[property]) &&
										breakout.label ===
											labelMapOverride[property]
									) {
										//Matched to field
										events[i].breakout = {
											label: breakout.label,
											field: property,
										};
										labelOverrideMatch = true;
										break;
									} else if (
										(!unusedMap || !unusedMap[property]) &&
										breakout.name === property
									) {
										labelOverrideMatch = true;
									}
								}
							}
							// Label match

							if (
								!labelOverrideMatch &&
								(!scheduleMatrix[scheduleID] ||
									!scheduleMatrix[scheduleID].breakout ||
									scheduleMatrix[scheduleID].breakout
										.label !== breakout.label)
							) {
								for (var property in labelMap) {
									if (
										(!unusedMap || !unusedMap[property]) &&
										breakout.label === labelMap[property]
									) {
										//Matched to field
										scheduleMatrix[scheduleID] = property;
										schedule.breakout = {
											label: breakout.label,
											field: property,
										};
										break;
									}
								}
							}

							// Default label match
							if (
								!labelOverrideMatch &&
								(!scheduleMatrix[scheduleID] ||
									!scheduleMatrix[scheduleID].breakout ||
									scheduleMatrix[scheduleID].breakout
										.label !== breakout.label)
							) {
								for (var property in defaultLabelMap) {
									if (
										(!unusedMap || !unusedMap[property]) &&
										breakout.label ===
											defaultLabelMap[property] &&
										(!labelMap || !labelMap[property])
									) {
										//Matched to field
										scheduleMatrix[scheduleID] = property;
										schedule.breakout = {
											label: breakout.label,
											field: property,
										};
										break;
									}
								}
							}

							// Clear breakout data from schedule if it is out of date
							if (!scheduleMatrix[scheduleID]) {
								schedule.breakout = null;
							}

							// Formatting for custom fields
							if (customField) {
								breakoutCustomField = customField;
								label = customField.name;

								if (
									customField.formatas === 'number' ||
									customField.formatas === 'currency' ||
									customField.formatas === 'percent'
								) {
									mutate = setMutateNumber(customField);
								} else if (customField.formatas === 'date') {
									sortField = 'name';
									mutate = function (name) {
										return fieldFormatting.formatDate(
											name,
											config.dateStringShortFormat
										);
									};
								} else if (
									customField.formatas === 'timestamp'
								) {
									sortField = 'name';
									mutate = function (name) {
										return fieldFormatting.formatDate(
											name,
											config.dateStringShortFormat +
												' ' +
												config.timeFormat
										);
									};
								} else {
									mutate = function (name) {
										if (
											name !== undefined &&
											name !== null &&
											typeof name !== 'string'
										) {
											return name.toString();
										} else {
											return name;
										}
									};
								}
							} else {
								if (events[i].breakout) {
									label = events[i].breakout.label;
								} else {
									label = schedule.breakout
										? schedule.breakout.label
										: breakout.name;
								}
								// label = schedule.labelMap ? schedule.labelMap[breakout.name] : defaultLabelMap[breakout.name] ? defaultLabelMap[breakout.name] : breakout.name;
							}

							// if (!scheduleMatrix[scheduleID]) {
							// 	scheduleMatrix[events.schedule.id] = ' ';
							// }

							if (events[i].breakout) {
								breakoutData =
									events[i][events[i].breakout.field];
							} else {
								breakoutData = schedule.breakout
									? events[i][schedule.breakout.field]
									: null;
							}

							if (Array.isArray(breakoutData)) {
								for (
									var ii = 0;
									ii < breakoutData.length;
									ii++
								) {
									setBreakoutMatrix(
										breakoutData[ii],
										label,
										mutate
									);
								}
							} else {
								setBreakoutMatrix(breakoutData, label, mutate);
							}
						}

						breakoutList = manageSettings.mutateFilterFieldList(
							breakoutDataMatrix,
							null,
							null,
							null,
							mutate,
							sortField
						);

						manageFilters.restoreSessionCollapseState(
							breakoutList,
							breakoutField,
							config.isShare &&
								config[breakoutField + 'Collapsed']
								? JSON.parse(
										config[breakoutField + 'Collapsed']
									)
								: null
						);

						seedcodeCalendar.init(
							'breakoutCustomFields',
							breakoutList,
							true
						);

						return breakoutList;

						function setBreakoutMatrix(
							breakoutData,
							label,
							mutate
						) {
							var display;
							if (
								breakoutData &&
								!breakoutDataMatrix[breakoutData] &&
								!schedule.isMeasureOnly &&
								!schedule.isMapOnly &&
								events[i].start.isSameOrBefore(view.end) &&
								events[i].end.isSameOrAfter(view.start) &&
								label === breakout.label
							) {
								display = mutate
									? mutate(
											$.fullCalendar.strpHTMLTags(
												breakoutData,
												true
											)
										)
									: $.fullCalendar.strpHTMLTags(
											breakoutData,
											true
										);
								breakoutData = breakoutData.toString(); // Make sure we are passing a string
								breakoutDataMatrix[breakoutData] = {
									name: breakoutData,
									display: display,
								};
							}
						}

						function setMutateNumber(customField) {
							var mutate = function (name) {
								var numberResult =
									fieldFormatting.formatDisplayNumber(
										name,
										customField,
										false
									);
								return numberResult.formattedNumber;
							};
							return mutate;
						}
					}
				};

				// Get currently available breakout items
				config.filterItems = function (filterField) {
					if (filterField === 'status') {
						return config.statusesAll();
					} else if (filterField === 'resource') {
						return config.resourcesAll();
					} else if (filterField === 'contact') {
						return config.contactsAll();
					} else if (filterField === 'project') {
						return config.projectsAll();
					}
				};

				//Grid View
				//Initialize our gridTimes
				config.gridTimes = manageGridTimes.init();

				//Callbacks
				config.dayDblClick = calendarEvents.onDayClick;
				config.dayNumberClick = calendarIO.viewDay;
				//dayClick: onDayClick, // we don't want to do anything on single click, only double click
				config.eventClick = calendarEvents.onEventClick;
				config.eventMouseover = calendarEvents.onEventMouseOver;
				config.eventMouseout = calendarEvents.onEventMouseOut;
				config.eventDragStart = calendarEvents.onEventDragStart;
				config.eventDrag = calendarEvents.onEventDrag;
				config.eventNoDrag = calendarEvents.onEventNoDrag;
				config.eventResizeStart = calendarEvents.onEventDragStart;
				config.eventDrop = calendarEvents.onEventDrop;
				config.eventCancelDrop = calendarEvents.onEventCancelDrop;
				config.drop = calendarEvents.onExternalEventDrop;
				config.onHorizonCollapse = calendarEvents.onHorizonCollapse;
				config.eventResize = calendarEvents.onEventResize;
				config.fetchStart = calendarEvents.onFetchStart;
				config.fetchEnd = calendarEvents.onFetchEnd;
				config.fetchUnscheduledStart =
					calendarEvents.onFetchUnscheduledStart;
				config.fetchUnscheduledEnd =
					calendarEvents.onFetchUnscheduledEnd;
				config.eventRender = calendarEvents.onEventRender;
				config.beforeEventRender = (event, events) => {
					return calendarEvents.beforeEventRender(event, events);
				};
				config.eventAfterRender = function (event, element, view) {
					//Reset modified status
					if (event.eventStatus && event.eventStatus.wasModified) {
						event.eventStatus.wasModified = false;
					}
					//Add touch interaction to events
					if (touchSupported) {
						element.addTouch();
					}
				};
				config.eventBeforeAllRender =
					calendarEvents.onEventBeforeAllRender;
				config.eventAfterAllRender =
					calendarEvents.onEventAfterAllRender;
				config.viewRender = calendarEvents.viewRender;
				config.loading = calendarEvents.onLoading;
				config.loadingUnscheduled = calendarEvents.onLoadingUnscheduled;

				config.eventShown = manageFilters.isEventShown;
				config.fieldExistsForEvent = calendarIO.fieldExistsForEvent;

				//Global conifg options that we can update in realtime without needing to re-render the calendar
				config.status = {
					preventDrag: false,
					preventResize: false,
				};

				//Set enviornment to config (isIOS, isIE, isChrome etc...)
				config.isMobileDevice = environment.isMobileDevice;
				config.isChrome = environment.isChrome;

				config.isSalesforce = fbk.isSalesforce();

				//Run on load calendar actions
				if (calendarActions && calendarActions.beforeCalendarRendered) {
					actionCallbacks = {
						confirm: function () {
							//Complete resources
							completeConfigLoading(config);
						},
						cancel: function () {
							// We assume that cancel means actions are taking over so stop loading settings
							// Wrap in evalAsync to trigger digest cycle as this is coming from async operation
							$rootScope.$evalAsync(() => {
								seedcodeCalendar.init(
									'loading-settings',
									false
								);
							});
						},
					};

					//Run load actions
					preventDefaultResult =
						manageCalendarActions.runCalendarActions(
							calendarActions.beforeCalendarRendered,
							actionCallbacks
						);
					if (preventDefaultResult) {
						return;
					}
				}

				//Load tracking or analytics
				if (config.doNotTrack && !preventEventLoading) {
					//Remove provider cookies
					dataStore.removeAllCookies('dbk', true);
				}

				completeConfigLoading(config);

				function completeConfigLoading(config) {
					//Run our callback and pass the config. This is used for promises waiting for this process to be done. The promise callback is our preferred method
					if (callback) {
						callback(config);
					}

					//Settings have now been fully loaded and we are ready to load anything that relies on them (triggers calendar render)
					seedcodeCalendar.init('loading-settings', false);

					//Check to see if the init function was run with the prevent event load flag
					if (!preventEventLoading) {
						// Watch for calendar view to be initialized (fullcalendar)
						watchCalendarViewRendered = $rootScope.$on(
							'calendarViewRendered',
							() => {
								watchCalendarViewRendered();
								initializeCalendarView();
							}
						);

						// Init calendar after timeout to make sure angular runs a digest cycle
						// This way all templates have a chance to react to scope variables like hiding the sidebar
						$timeout(() => {
							// Tell the calendar view to render (fullcalendar)
							seedcodeCalendar.init('initCalendar', true);
						}, 0);
					}
				}
			}

			function initializeCalendarView() {
				var config = seedcodeCalendar.get('config');

				if (_CONFIG.DBK_USE_STATIC_DATA) {
					window.setTimeout(function () {
						utilities.showModal(
							'Warning',
							'<p>DayBack is temporarily running in a reduced capacity. All settings will be read only during this time.</p><p>You can view and edit your events like normal but changes to settings or bookmarks will not be retained. <a href="" ng-click="help(\'https://dayback.com/outage/\', \'https://dayback.com/outage/\', true)"><span translate>More info...</span></a></p>',
							null,
							null,
							'OK',
							null,
							null,
							null,
							false,
							null,
							true
						);
					}, 1000);
				}

				//Add url watcher to refresh events when a reload=true parameter is set (in the url)
				watchRefreshFromURL();

				//Run on load calendar actions
				if (calendarActions && calendarActions.load) {
					//Run load actions
					manageCalendarActions.runCalendarActions(
						calendarActions.load
					);
				}

				//Initialize polling loop
				updateCalendarData();

				//Get our source schedules
				applySchedules();

				//Run calendar actions
				if (calendarActions && calendarActions.afterCalendarRendered) {
					//Run load actions
					manageCalendarActions.runCalendarActions(
						calendarActions.afterCalendarRendered,
						null
					);
				}

				dataStore.saveState('sessionUser', config.sessionUser, true);
			}

			function applySchedules() {
				const shareSources = seedcodeCalendar.get('shareSources');
				const sourceTemplates = seedcodeCalendar.get('sourceTemplates');
				// Init schedules
				seedcodeCalendar.init('schedules', []);

				//Restore source type folder collapse state
				manageSchedules.initializeSourceFolders(sourceTemplates);

				manageEventSources
					.getSchedules(loadSchedules)
					.then((result) => {
						completeScheduleLoad();
					})
					.catch((err) => {
						// There was an error probably becuase we left the calendar to admin settings
						// Nothing to do as we just want things to end here
					});

				//Add source templates to share sources if they exist
				if (shareSources) {
					for (let property in shareSources) {
						shareSources[property].sourceTemplate =
							manageEventSources.getTemplate(
								shareSources[property].sourceTypeID
							);
					}
				}
			}

			function loadSchedules(result, sourceTemplate) {
				manageEventSources.loadSchedules(
					result,
					sourceTemplate,
					enableOverrideFunc,
					loadScheduleCallback,
					loadCompleteCallback
				);
				function enableOverrideFunc(schedule, currentSelection) {
					var defaultItems =
						urlParams.source && urlParams.source.length
							? urlParams.source
							: [];
					var resetSources =
						defaultItems && defaultItems[0] === 'null';
					if (defaultItems.length) {
						for (var i = 0; i < defaultItems.length; i++) {
							if (defaultItems[i] === schedule.name) {
								return true;
							}
						}
						if (resetSources) {
							return false;
						} else {
							return currentSelection;
						}
					} else {
						return currentSelection;
					}
				}

				function loadScheduleCallback(source) {
					//Track how many schedules have loaded
					loadedScheduleCount++;
				}

				function loadCompleteCallback(scheduleResult, hasSelected) {
					if (hasSelected) {
						// hasSelectedSchedule will be cleared after events rendered
						seedcodeCalendar.init(
							'renderHasSelectedSchedule',
							true,
							true
						);
					}
				}
			}

			function completeScheduleLoad() {
				var shareSchedule;
				var schedules;
				var hasSelectedSchedule = seedcodeCalendar.get(
					'renderHasSelectedSchedule'
				);

				//If all sources and schedules have loaded then we can make sure we update our selected state
				// Init and broadcast that schedules are loaded
				seedcodeCalendar.init('schedulesLoaded', true);
				calendarEvents.setSchedulesLoaded();

				if (!hasSelectedSchedule) {
					calendarEvents.onEventAfterAllRender(null, null, true);
				}

				//set active schedules from bookmark
				if (bookmark && bookmark.schedules) {
					schedules = seedcodeCalendar.get('schedules');
					for (var i = 0; i < schedules.length; i++) {
						shareSchedule =
							bookmark.schedules[
								utilities.stringToID(schedules[i].id)
							];
						if (
							(!shareSchedule && schedules[i].status.selected) ||
							(shareSchedule &&
								shareSchedule.status.selected !==
									schedules[i].status.selected)
						) {
							manageSchedules.selectSchedule(schedules[i]);
						}
					}
				}

				//Write selected schedule state to storage
				manageSchedules.saveSelected(true, true);
			}

			//Process for polling for changes. Currently used for notifications could be used for other items that may need to update without user intervention
			function updateCalendarData() {
				const delaySeconds = 24 * 60; // 24 hours
				const currentTimeout = seedcodeCalendar.get(
					'update-notifications'
				);
				if (currentTimeout) {
					window.clearTimeout(currentTimeout);
				}
				//Make sure we call this function again after delay time
				const updateTimeout = window.setTimeout(function () {
					updateCalendarData();
				}, delaySeconds * 1000);
				updateNotificationData(updateTimeout);
			}

			/** @type {(timeout: setTimeout) => void} */
			function updateNotificationData(timeout) {
				seedcodeCalendar.init('update-notifications', timeout);
			}

			function updateViewDayRange(config, range) {
				var dayRange;
				if (!config) {
					config = seedcodeCalendar.get('config');
				}
				//If we have a range specified let's set it for the specific view we are on
				if (range && range >= 0) {
					dayRange =
						Math.abs(
							moment(config.defaultDate).diff(range, 'days')
						) + 1;
					if (config.defaultView === 'basicHorizon') {
						config.horizonDays = range;
						config.horizonSlider =
							$.fullCalendar.horizonDaysToSlider(range);
					} else if (config.defaultView.indexOf('Resource') > -1) {
						config.resourceDays = Math.min(
							Math.max(1, dayRange),
							30
						);
					}
				}
			}

			function updateConfig(item, value, config) {
				if (!config) {
					config = seedcodeCalendar.get('config');
				}
				config[item] = value;

				return config;
			}

			function isValidParamDate(date) {
				if (!date) {
					return false;
				}

				var error = {};
				var translations = $translate.instant([
					'Invalid Date',
					'Invalid date message',
					'Continue',
					'Learn More',
				]);
				var isValid = moment(date).isValid();

				if (isValid) {
					return true;
				}

				error.cancelButtonText = translations['Learn More'];
				error.cancelFunction = function () {
					utilities.help(
						'Navigation',
						'79-add-dayback-buttons-to-your-salesforce-pages'
					);
				};
				error.confirmButtonText = translations['Continue'];
				error.title = translations['Invalid Date'];
				error.content = translations['Invalid date message'];
				utilities.showModal(
					error.title,
					error.content,
					error.cancelButtonText,
					error.cancelFunction,
					error.confirmButtonText,
					null
				);

				return false;
			}

			function isValidParamView(viewName) {
				if (!viewName) {
					return false;
				}

				var error = {};
				var translations = $translate.instant([
					'Invalid View',
					'Invalid view message',
					'Continue',
					'Learn More',
				]);
				var viewOptions =
					seedcodeCalendar.calendar.configMap.view.options;

				for (var i = 0; i < viewOptions.length; i++) {
					if (viewName === viewOptions[i].id) {
						return true;
					}
				}
				error.cancelButtonText = translations['Learn More'];
				error.cancelFunction = function () {
					utilities.help(
						'Navigation',
						'79-add-dayback-buttons-to-your-salesforce-pages'
					);
				};
				error.confirmButtonText = translations['Continue'];
				error.title = translations['Invalid View'];
				error.content = translations['Invalid view message'];
				utilities.showModal(
					error.title,
					error.content,
					error.cancelButtonText,
					error.cancelFunction,
					error.confirmButtonText,
					null
				);
				return false;
			}

			function isValidParamBookmarkID(bookmarkID, callback) {
				if (!bookmarkID) {
					callback(false);
				} else {
					//Get Bookmark data and update calendar
					shares.getBookmark(bookmarkID, function (result) {
						if (!result) {
							var error = {};
							var translations = $translate.instant(
								[
									'Invalid Bookmark ID',
									'Invalid Bookmark ID message',
									'Continue',
									'Learn More',
								],
								{bookmarkID: bookmarkID}
							);
							error.cancelButtonText = translations['Learn More'];
							error.cancelFunction = function () {
								utilities.help(
									'Navigation',
									'110-dayback-urls'
								);
							};
							error.confirmButtonText = translations['Continue'];
							error.title = translations['Invalid Bookmark ID'];
							error.content =
								translations['Invalid Bookmark ID message'];
							utilities.showModal(
								error.title,
								error.content,
								error.cancelButtonText,
								error.cancelFunction,
								error.confirmButtonText,
								null
							);
						}
						callback(result);
					});
				}
			}

			function getURLParams() {
				//Get url hash params
				var source = $location.search().source;
				var filterStatuses = $location.search().filterStatuses;
				var filterResources = $location.search().filterResources;
				var filterProjects = $location.search().filterProjects;
				var filterContacts = $location.search().filterContacts;

				return {
					id: $location.search().id,
					date: $location.search().date,
					view: $location.search().view,
					range: $location.search().range,
					refresh: $location.search().refresh,

					saveState: $location.search().saveState,

					resourceDays: $location.search().resourceDays,
					resourceColumns: $location.search().resourceColumns,

					weekCount: $location.search().weekCount,

					contactID: $location.search().contactID,
					contactName: $location.search().contactName,
					projectID: $location.search().projectID,
					projectName: $location.search().projectName,

					bookmarkID: $location.search().bookmarkID,

					sidebarShow: $location.search().sidebarShow,
					sidebarLock: $location.search().sidebarLock,

					unscheduledShow: $location.search().unscheduledShow,
					unscheduledFilter: $location.search().unscheduledFilter,

					filterText: $location.search().filterText,

					resourceListFilter: $location.search().resourceListFilter,

					source: Array.isArray(source)
						? source
						: source
							? [source]
							: [],

					filterStatuses: Array.isArray(filterStatuses)
						? filterStatuses
						: filterStatuses
							? [filterStatuses]
							: [],
					filterResources: Array.isArray(filterResources)
						? filterResources
						: filterResources
							? [filterResources]
							: [],
					filterProjects: Array.isArray(filterProjects)
						? filterProjects
						: filterProjects
							? [filterProjects]
							: [],
					filterContacts: Array.isArray(filterContacts)
						? filterContacts
						: filterContacts
							? [filterContacts]
							: [],
				};
			}

			function cleanURL(urlParams) {
				if (environment.isShare) {
					return;
				}

				for (var property in urlParams) {
					$location.search(property, null);
				}
			}

			function watchRefreshFromURL() {
				var config = seedcodeCalendar.get('config');
				var preventChangeEvent;
				//Watch for our initial event rendering. This way we know if the page has fully loaded before we proceed
				var watchForInitialRender = $rootScope.$on(
					'eventsRendered',
					function (ev, options) {
						//Initial render is to display the calendar and will have no events yet. So make sure we don't run first load until second render
						if (options.schedulesLoaded) {
							eventsFirstLoad();
						}
					}
				);

				function eventsFirstLoad() {
					//stop listening for broadcast
					watchForInitialRender();
					//Check if we need to show a popover
					if (urlParams.id) {
						showEventOnCalendar(urlParams.id);
					}
				}
				//If the url has changed we need to either run our routine or record that it has changed so we can run after render is complete
				window.addEventListener('hashchange', initURLWatcher, false);

				function initURLWatcher() {
					var path = $location.path();
					// Remove event listener if we are leaving the main calendar view. We will add it back next time init is called
					if (path !== '/') {
						window.removeEventListener(
							'hashchange',
							initURLWatcher,
							false
						);
						return;
					}
					$rootScope.$evalAsync(function () {
						if (
							preventURLChangeEvent ||
							Object.keys($location.search()).length === 0
						) {
							preventURLChangeEvent = false;
							return;
						}
						var urlParams = getURLParams();
						urlChanged(urlParams);
						//Remove the url params from url so we don't polute the url
						preventURLChangeEvent = true;
						cleanURL(urlParams);
					});
				}

				function urlChanged(urlParams) {
					var event;
					var loadedSource;

					//Close any possible open popovers
					$rootScope.$broadcast('closePopovers');

					if (urlParams.bookmarkID) {
						//Get Bookmark data and update calendar
						isValidParamBookmarkID(
							urlParams.bookmarkID,
							function (result) {
								if (result) {
									bookmark = result;
									shares.update(
										urlParams.bookmarkID,
										function (updatedShare, error) {
											if (error) {
												utilities.showModal(
													error.title ||
														'Unable to visit share',
													error.content,
													error.cancelButtonText ||
														'ok'
												);
											}
										},
										null,
										true
									);
								}
							}
						);
					}
					if (urlParams.saveState) {
						if (urlParams.saveState === 'false') {
							config.saveState = false;
						} else {
							config.saveState = true;
						}
					}
					if (urlParams.source) {
						//Load the source for this event
						manageSchedules.restoreSources(urlParams.source);
					}
					if (urlParams.date) {
						if (isValidParamDate(urlParams.date)) {
							seedcodeCalendar
								.get('element')
								.fullCalendar('gotoDate', urlParams.date);
						}
					}
					if (urlParams.view) {
						if (isValidParamView(urlParams.view)) {
							seedcodeCalendar
								.get('element')
								.fullCalendar('changeView', urlParams.view);
						}
					}
					if (urlParams.range) {
						updateViewDayRange(null, urlParams.range);
						seedcodeCalendar.init('initCalendar');
					}
					if (urlParams.resourceDays) {
						manageResources.updateDays(
							Number(urlParams.resourceDays),
							false
						);
					}
					if (urlParams.resourceColumns) {
						manageResources.updateColumns(
							Number(urlParams.resourceColumns),
							false
						);
					}
					if (urlParams.resourceDays) {
						manageResources.updateDays(
							Number(urlParams.resourceDays),
							false
						);
					}
					if (urlParams.weekCount) {
						updateConfig('weekCount', Number(urlParams.weekCount));
						seedcodeCalendar.init('initCalendar');
					}
					if (urlParams.filterStatuses.length) {
						manageFilters.restoreFilters(
							'statuses',
							urlParams.filterStatuses,
							null,
							share ? share : bookmark
						);
						manageResources.reset();
					}
					if (urlParams.filterResources.length) {
						manageFilters.restoreFilters(
							'resources',
							urlParams.filterResources,
							null,
							share ? share : bookmark
						);
						manageResources.reset();
					}
					if (urlParams.resourceListFilter) {
						var resourceListFilter =
							urlParams.resourceListFilter === 'null'
								? ''
								: urlParams.resourceListFilter;
						manageFilters.applyFilterSearch(
							resourceListFilter,
							'resource',
							true,
							true,
							null
						);
					}
					if (urlParams.filterText) {
						manageFilters.restoreFilters(
							'text',
							urlParams.filterText,
							null,
							share ? share : bookmark
						);
						manageResources.reset();
					}
					if (urlParams.filterProjects.length) {
						seedcodeCalendar.init(
							'projects',
							manageFilters.createAdvanceFilter(
								urlParams.filterProjects,
								'projects'
							)
						);
						manageFilters.restoreFilters(
							'projects',
							urlParams.filterProjects,
							null,
							share ? share : bookmark
						);
						var projects = seedcodeCalendar.get('projects');
						if (projects && projects.length) {
							config.hasProjects = true;
						} else {
							config.hasProjects = false;
						}

						manageResources.reset();
					}
					if (urlParams.filterContacts.length) {
						seedcodeCalendar.init(
							'contacts',
							manageFilters.createAdvanceFilter(
								urlParams.filterContacts,
								'contacts'
							)
						);
						manageFilters.restoreFilters(
							'contacts',
							urlParams.filterContacts,
							null,
							bookmark
						);

						var contacts = seedcodeCalendar.get('contacts');
						if (contacts && contacts.length) {
							config.hasContacts = true;
						} else {
							config.hasContacts = false;
						}

						manageResources.reset();
					}
					//parameters for lookups
					if (urlParams.contactID && urlParams.contactName) {
						if (urlParams.contactID) {
							config.lookups.contactID =
								urlParams.contactID === 'null'
									? ''
									: urlParams.contactID;
							config.lookups.contactName =
								urlParams.contactName === 'null'
									? ''
									: urlParams.contactName;
						}
					}
					if (urlParams.projectID && urlParams.projectName) {
						if (urlParams.projectID) {
							config.lookups.projectID =
								urlParams.projectID === 'null'
									? ''
									: urlParams.projectID;
							config.lookups.projectName =
								urlParams.projectName === 'null'
									? ''
									: urlParams.projectName;
						}
					}
					if (urlParams.refresh) {
						//fetching resources bypasses the On Resources Fetched action, so can't be used with dynammic lists
						//manageResources.fetchAndReset();
						seedcodeCalendar
							.get('element')
							.fullCalendar('refetchEvents');
					}
					if (urlParams.id) {
						//Check if we may be performing an operation that could potentially render events. If so wait for render otherwise show the event
						if (
							urlParams.source ||
							urlParams.date ||
							urlParams.view ||
							urlParams.refresh ||
							urlParams.range
						) {
							var eventObj = calendarIO.getDisplayEvent(
								urlParams.id
							);
							if (!eventObj) {
								//Open popover after events have rendered
								showEventAfterRender();
							} else {
								showEventOnCalendar(urlParams.id);
							}
						} else {
							showEventOnCalendar(urlParams.id);
						}
					}
					if (urlParams.sidebarShow) {
						var sidebar = seedcodeCalendar.get('sidebar');
						config.showSidebar =
							urlParams.sidebarShow === 'false' ? false : true;
						dataStore.saveState('showSidebar', config.showSidebar);
						sidebar.show =
							touchSupported || environment.isExtension
								? false
								: config.showSidebar;
					}

					if (
						urlParams.unscheduledShow ||
						urlParams.unscheduledFilter
					) {
						const altView = seedcodeCalendar.get('alt-view');
						if (!altView) {
							altView.enabled =
								config.unscheduledEnabled || config.mapEnabled;
							altView.show =
								(config.unscheduledEnabled ||
									config.mapEnabled) &&
								config.showAltView;
						}
						if (urlParams.unscheduledShow) {
							config.showAltView =
								urlParams.unscheduledShow === 'false'
									? false
									: true;
							altView.show = config.showAltView;
						}
						if (urlParams.unscheduledFilter) {
							config.unscheduledFilter =
								urlParams.unscheduledFilter.toLowerCase() ===
								'null'
									? ''
									: urlParams.unscheduledFilter;
							altView.unscheduledFilter =
								config.unscheduledFilter;
							altView.filterFromURL = true;
						}
						seedcodeCalendar.init('alt-view', altView);
					}

					function showEventAfterRender() {
						//Create listener for when events render, will be triggered by loading source
						var sourcesRendered = $rootScope.$on(
							'eventsRendered',
							function () {
								sourcesRendered();
								showEventOnCalendar(urlParams.id);
							}
						);
					}

					//Broadcast that the event has been modified (updates popover data)
					//$rootScope.$broadcast('event');

					//Delete eventID
					//calendarIO.deleteEventNow(event);
				}

				function showEventOnCalendar(eventID) {
					var eventsRendered;
					var event = calendarIO.getDisplayEvent(eventID);
					if (!event) {
						return;
					}
					//Check if the event is filtered. If it is let's show it.
					if (!manageFilters.isEventShown(event)) {
						eventsRendered = $rootScope.$on(
							'eventsRendered',
							function () {
								//Kill our $on event so we don't keep watching for the events to be rendered
								eventsRendered();
								event = calendarIO.getDisplayEvent(
									event.eventID
								);
								showPopover(event);
							}
						);
						manageFilters.clearFilters();
					} else {
						showPopover(event);
					}
				}

				//Run routine to show popover once we have navigated to and located event
				function showPopover(event) {
					//Rerender our event setting the show popover flag
					event.eventStatus = {showPopover: true};
					seedcodeCalendar
						.get('element')
						.fullCalendar('rerenderEvents');
					return event;
				}
			}
		}
	}

	function manageGridTimes(
		dataStore,
		seedcodeCalendar,
		manageSettings,
		environment
	) {
		var columnCount;
		var gridStartTime;
		var columnStart;
		var columnEnd;
		var totalTimeColumnCount;
		var gridTimes;

		return {
			getGridTimes: getGridTimes,
			getViewedTimes: getViewedTimes,
			getStartTime: getStartTime,
			nextPossible: nextPossible,
			previousPossible: previousPossible,
			next: next,
			previous: previous,
			reset: reset,
			init: init,
		};

		function getGridTimes() {
			var gridTimesResult = [];
			var config = seedcodeCalendar.get('config');
			var minTime = moment.duration(config.minTime);
			var maxTime = moment.duration(config.maxTime);
			var slotDuration = moment.duration(config.slotDuration);

			var slotTime = moment.duration(minTime);

			while (slotTime < maxTime) {
				gridTimesResult.push(moment.duration(slotTime));

				slotTime.add(slotDuration);
			}
			gridTimesResult = gridTimesResult.length
				? gridTimesResult
				: [moment.duration(slotTime)];
			totalTimeColumnCount = gridTimesResult.length;

			gridTimes = gridTimesResult;

			return gridTimesResult;
		}

		function getViewedTimes() {
			var result = [];
			var config = seedcodeCalendar.get('config');
			var gridTimes = getGridTimes();
			var startTime = moment.duration(gridStartTime);
			var gridTime;
			var col = 0;
			columnStart = null;

			for (var i = 0; i < gridTimes.length; i++) {
				if (gridTimes[i] >= startTime && col < columnCount) {
					//Set our first column
					if (!col) {
						columnStart = i;
					}
					result.push(gridTimes[i]);
					col++;
				}
			}
			if (columnStart === null) {
				columnStart = gridTimes.length;
			}
			//If our result does not fill the appropriate column count add more items to the beginning of the array
			while (result.length < columnCount && columnStart > 0) {
				columnStart--;
				result.unshift(gridTimes[columnStart]);
			}
			if (result.length === 0) {
				columnStart = 0;
				result.push(gridTimes[columnStart]);
			}

			columnEnd =
				columnStart + columnCount > totalTimeColumnCount
					? totalTimeColumnCount
					: columnStart + columnCount;

			//Reset grid start time just in case it has changed
			gridStartTime = moment.duration(gridTimes[columnStart]);

			return result;
		}

		function getTimeCount() {
			return getGridTimes().length;
		}
		function getStartTime() {
			return gridStartTime;
		}
		function nextPossible() {
			return columnEnd < totalTimeColumnCount;
		}
		function previousPossible() {
			return columnStart > 0;
		}
		function next(alt) {
			var viewed;
			var config = seedcodeCalendar.get('config');

			if (nextPossible()) {
				if (alt) {
					columnStart++;
				} else {
					columnStart =
						columnStart + columnCount >= totalTimeColumnCount
							? totalTimeColumnCount - columnCount
							: (columnStart += columnCount);
				}
				gridStartTime = gridTimes[columnStart];
				viewed = getViewedTimes();
				config.gridTimes = viewed;

				dataStore.saveState(
					'gridStartTime',
					gridStartTime.asMilliseconds()
				);
				seedcodeCalendar
					.get('element')
					.fullCalendar('updateOptions', 'gridTimes', viewed, true);
			}
		}
		function previous(alt) {
			var viewed;
			var scrollCount = columnEnd;

			if (previousPossible()) {
				var config = seedcodeCalendar.get('config');
				if (alt) {
					columnStart--;
				} else {
					columnStart =
						columnStart - columnCount <= 0
							? 0
							: (columnStart -= columnCount);
				}
				gridStartTime = gridTimes[columnStart];
				viewed = getViewedTimes();
				config.gridTimes = viewed;
				dataStore.saveState(
					'gridStartTime',
					gridStartTime.asMilliseconds()
				);
				seedcodeCalendar
					.get('element')
					.fullCalendar('updateOptions', 'gridTimes', viewed, true);
			}
		}
		function reset() {
			var config = seedcodeCalendar.get('config');

			gridStartTime = moment.duration(
				seedcodeCalendar.get('config').scrollTime
			);
			dataStore.saveState(
				'gridStartTime',
				gridStartTime.asMilliseconds()
			);

			var viewed = init();
			seedcodeCalendar
				.get('element')
				.fullCalendar('updateOptions', 'gridTimes', viewed, true);
		}
		function init(gridTimeColumns) {
			var config = seedcodeCalendar.get('config');
			var viewed;

			if (!gridTimeColumns) {
				//Check if we are on a phone. If so display a pre-defined column count
				gridTimeColumns = environment.isPhone
					? 5
					: config.gridTimeColumns;
			}

			totalTimeColumnCount = getTimeCount();
			columnCount = Number(gridTimeColumns);

			gridStartTime = moment.duration(
				Number(dataStore.getState('gridStartTime')) ||
					moment.duration(seedcodeCalendar.get('config').scrollTime)
			);
			dataStore.saveState(
				'gridStartTime',
				gridStartTime.asMilliseconds()
			);
			viewed = getViewedTimes();
			if (config) {
				config.gridTimes = viewed;
			}
			return viewed;
		}
	}

	function manageResources(
		dataStore,
		seedcodeCalendar,
		manageSettings,
		manageConfig,
		$timeout
	) {
		var columnStart;
		var columnMax;
		var config;

		return {
			updateDays: updateDays,
			updateColumns: updateColumns,
			getResourceColumns: getResourceColumns,
			getAllResourceCount: getAllResourceCount,
			getResourceCount: getResourceCount,
			getFiltered: getFiltered,
			getViewed: getViewed,
			pageCount: pageCount,
			currentPage: currentPage,
			nextPossible: nextPossible,
			previousPossible: previousPossible,
			next: next,
			previous: previous,
			updateResourceColumns: updateResourceColumns,
			reset: reset,
			fetchAndReset: fetchAndReset,
			init: init,
		};
		function updateDays(days, preventRefresh) {
			var config = seedcodeCalendar.get('config');
			config.resourceDays = days;
			manageConfig.applyLocalSetting(
				'resourceDays',
				true,
				preventRefresh
			);
		}

		function updateColumns(columns, refetch) {
			if (refetch) {
				getResources(initializeResourceColumns);
			} else {
				initializeResourceColumns();
			}

			function initializeResourceColumns(resourcesResult) {
				if (resourcesResult) {
					seedcodeCalendar.init('resources', resourcesResult);
					//Use timeout so we run this after the init sends out it's broadcast this way view data can update
					$timeout(function () {
						init(Number(columns));
						reset();
					}, 0);
				} else {
					init(Number(columns));
					reset();
				}
			}
		}

		function getResourceColumns() {
			return columnMax;
		}

		function getAllResourceCount() {
			return getFiltered(true).length;
		}

		function getResourceCount() {
			return getFiltered().length;
		}

		function getFiltered(all, type) {
			var hash = {};
			var isFiltered;
			var output = [];
			var selected;
			var filterItems;
			var hasValidFilter;
			var config = seedcodeCalendar.get('config');
			if (!config) {
				return output;
			}

			if (!type) {
				type = 'resources';
			}

			var resourcesList =
				config.resourcesFiltered && !all && type === 'resources'
					? config.resourcesFiltered
					: seedcodeCalendar.get(type);

			if (!resourcesList) {
				return output;
			}
			filterItems = resourcesList.filter(function (value, index, array) {
				if (all || (value.status && value.status.selected)) {
					isFiltered = true;
				}
				return !value.isFolder;
			});

			// Create the filter list
			createFilterList(all);

			// If there is no valid filter selected assume that all should be returned (prevents resource views from getting an error)
			if (!hasValidFilter) {
				createFilterList(true);
			}

			return output;

			function createFilterList(all) {
				for (var i = 0; i < filterItems.length; i++) {
					selected =
						all ||
						(filterItems[i].status &&
							filterItems[i].status.selected);
					if (
						(!isFiltered && !hash[filterItems[i].name]) ||
						(selected && !hash[filterItems[i].name])
					) {
						output.push(filterItems[i]);
						//Could wrap this line in an if statement if we wanted to only prevent duplicates in certain instances
						hash[filterItems[i].name] = true;

						if (selected && !filterItems[i].isFolder) {
							hasValidFilter = true;
						}
					}
				}
			}
		}

		function getViewed() {
			var filteredResources = getFiltered();
			//We clone the resources object because if we reference this in an ng-repeat that modifies the original object and triggers any watchers on this array
			return JSON.parse(JSON.stringify(filteredResources)).slice(
				columnStart,
				columnStart + columnMax
			);
		}

		function pageCount() {
			var resourceCount = getResourceCount();
			return Math.ceil(resourceCount / columnMax);
		}

		function currentPage() {
			var resourceCount = getResourceCount();
			return Math.ceil((columnStart + columnMax) / columnMax);
		}

		function nextPossible() {
			var resourceCount = getResourceCount();
			return columnStart < resourceCount - columnMax;
		}

		function previousPossible() {
			return columnStart > 0;
		}

		function next(alt) {
			var viewed;
			var scrollCount = columnMax;
			var resourceCount = getResourceCount();

			if (nextPossible()) {
				if (alt) {
					columnStart++;
				} else {
					columnStart =
						columnStart + scrollCount >= resourceCount - columnMax
							? resourceCount - columnMax
							: (columnStart += scrollCount);
				}
				viewed = getViewed();
				config.resources = viewed;
				config.resourcePosition = columnStart;
				seedcodeCalendar
					.get('element')
					.fullCalendar('updateResources', viewed);
				manageConfig.applyLocalSetting('resourcePosition', true, true);
			}
		}
		function previous(alt) {
			var viewed;
			var resourceCount = getResourceCount();
			var scrollCount = columnMax;

			if (previousPossible()) {
				if (alt) {
					columnStart--;
				} else {
					columnStart =
						columnStart - scrollCount <= 0
							? 0
							: (columnStart -= scrollCount);
				}
				viewed = getViewed();
				config.resources = viewed;
				config.resourcePosition = columnStart;
				seedcodeCalendar
					.get('element')
					.fullCalendar('updateResources', viewed);
				manageConfig.applyLocalSetting('resourcePosition', true, true);
			}
		}

		function updateResourceColumns(fromViewChange) {
			var totalResourceCount = getAllResourceCount();
			var normalizedResourceCount = Number(config.resourceColumns);
			if (
				isNaN(normalizedResourceCount) ||
				normalizedResourceCount > totalResourceCount
			) {
				normalizedResourceCount = totalResourceCount;
			}

			columnStart = Number(config.resourcePosition);
			columnMax = normalizedResourceCount;

			if (config) {
				config.resourceColumns = columnMax;
				config.resources = getViewed();
			}

			config.resources = getViewed();
			seedcodeCalendar
				.get('element')
				.fullCalendar(
					'updateResources',
					config.resources,
					false,
					false,
					fromViewChange
				);
			manageConfig.applyLocalSetting('resourcePosition', true, true);
		}

		function getResources(callback) {
			//Get updated list of resources
			manageSettings.getResources(afterFetch);
			//Callback function to process resources
			function afterFetch(resourcesResult) {
				var resources = seedcodeCalendar.get('resources');
				//Retain resource status (collapse state etc)
				for (var i = 0; i < resourcesResult.length; i++) {
					for (var ii = 0; ii < resources.length; ii++) {
						if (resourcesResult[i].name === resources[ii].name) {
							if (resources[ii].status) {
								resourcesResult[i].status =
									resources[ii].status;
							}
							continue;
						}
					}
				}

				if (callback) {
					callback(resourcesResult);
				}
			}
		}

		function reset(refetch, resetScroll, preventFilterRefetch) {
			// Check schedules for setting to only fetch when a filter is applied
			var schedules = seedcodeCalendar.get('schedules');
			if (!schedules) {
				// If there are no schedules then we are missing required data and can't continue
				return;
			}

			if (!preventFilterRefetch) {
				for (var i = 0; i < schedules.length; i++) {
					if (
						schedules[i].requireFilters ||
						schedules[i].requireResourceFilters
					) {
						refetch = true;
						break;
					}
				}
			}

			columnStart = 0;
			config.resources = getViewed();
			config.resourcePosition = columnStart;
			seedcodeCalendar
				.get('element')
				.fullCalendar(
					'updateResources',
					config.resources,
					refetch,
					resetScroll,
					false,
					preventFilterRefetch
				);
			manageConfig.applyLocalSetting('resourcePosition', true, true);
		}
		function fetchAndReset() {
			getResources(function (resourcesResult) {
				seedcodeCalendar.init('resources', resourcesResult);
				reset(true);
			});
		}
		function init(resourceCount) {
			var totalResourceCount = getAllResourceCount();
			var normalizedResourceCount = Number(resourceCount);
			if (
				isNaN(normalizedResourceCount) ||
				normalizedResourceCount > totalResourceCount
			) {
				normalizedResourceCount = totalResourceCount;
			}

			config = seedcodeCalendar.get('config');
			columnStart = Number(config?.resourcePosition || 0);
			columnMax = normalizedResourceCount;
			if (config) {
				config.resourceColumns = columnMax;
				config.resources = getViewed();
				manageConfig.applyLocalSetting('resourceColumns', true, true);
				// manageConfig.applyLocalSetting('resources', true, true);
			}
			return getViewed();
		}
	}
})();
